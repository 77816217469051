import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderTopRoutingModule } from './header-top-routing.module';
import { HeaderTopComponent } from './header-top.component';
import { ModalModule } from 'ngx-bootstrap/modal';


@NgModule({
  declarations: [HeaderTopComponent],
  imports: [
    CommonModule,
    HeaderTopRoutingModule,
    ModalModule.forRoot()
  ],
  exports:[
    HeaderTopComponent
  ]
})
export class HeaderTopModule { }
