import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonDataSharingService } from '../base-service/common-data-sharing.service';
import { CommonMessageService } from '../base-service/common-message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshForm = new FormGroup({
    client_id: new FormControl('2'),
    client_secret: new FormControl('drekwdFfu4FNSEHk0ZgiMcPWK1TgnV6QpI8CPu2g'),
    grant_type: new FormControl('refresh_token'),
    username: new FormControl(''),
    password: new FormControl(''),
    refresh_token: new FormControl(''),
    scope: new FormControl(''),
    provider: new FormControl(''),
  });
  private logoutApi = 'auth/logout';

  constructor(
    private commonDataSharing: CommonDataSharingService,
    private commonMsgService: CommonMessageService,
    private http: HttpClient,
    private router: Router
  ) { }

  public getToken():any | null {
    if (this.commonDataSharing.getIsBrowser()) {
      let auth;
      auth = JSON.parse(this.commonDataSharing.getLocalStorage('at'));
      return auth;
    } else {
      return null;
    }
  }

  public getRefreshToken():any | null {
    if (this.commonDataSharing.getIsBrowser() && this.commonDataSharing.getLocalStorage('at')) {
      let auth;
      auth = JSON.parse(this.commonDataSharing.getLocalStorage('at'));
      return auth ? auth.refresh_token : null;
    } else {
      return null;
    }
  }

  public isLoggednIn():boolean {
    return this.getToken() !== null;
  }

  public handle404Error():void {
    this.router.navigateByUrl("/page-not-found")
  }

  public refreshToken(): Observable<Object> {
    const auth = JSON.parse(this.commonDataSharing.getLocalStorage('at'));
    this.refreshForm.patchValue({
      refresh_token: auth.refresh_token,
    });
    return this.http
      .post<any>(environment.baseUrl + 'auth/login', this.refreshForm.value)
      .pipe(
        tap((tokens: any) => {
          this.commonDataSharing.setLocalStorage('at', JSON.stringify(tokens));
        })
      );
  }

  public logout():void {
    if (this.commonDataSharing.getIsBrowser()) {
      this.commonDataSharing.removeLocalStorage('at');
      this.commonDataSharing.removeLocalStorage('recent-products');
      this.commonDataSharing.removeLocalStorage('code');
      this.commonDataSharing.removeLocalStorage('cartNum');
      this.commonDataSharing.emitLogiNStatus(false);
      this.router.navigateByUrl('/login');
    }
  }

  /*
  * Display alert message when refresh token expires
  */
 public displayTokenExpiredMessage():void {
    this.commonMsgService.emitAlertMessage('Your session has been expired. Please log in again', 'danger');
  }
}
