import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  private sticky: boolean = false;
  private scrollheight: number = 163;

  @HostListener('window:scroll') onScroll(e: Event): void {
    // let scroll = document.getElementById('header-sticky').scrollTop;
    let scroll =
      window.scrollY +
      this.scrollheight -
      document.getElementById('header-sticky').offsetTop;
    if (scroll < 300) {
      this.sticky = false;
    } else {
      this.sticky = true;
    }
    if (window.scrollY < 400) {
      let scrollUp = document.getElementById('scrollUp');
      scrollUp.classList.add('d-none');
    } else {
      let scrollUp = document.getElementById('scrollUp');
      scrollUp.classList.remove('d-none');
    }
  }

  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    // -- Initially set title to Sewa --
    this.titleService.setTitle('Sewa');
  }

  private onActivate() {
    window.scroll(0, 0);
  }

}
