import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonMessageService {

  constructor() {
  }

  public checkRegister: boolean = false;
  public checkCartUpdate: boolean = false;
  public checkPwChange: boolean = false;
  public checkCartCategory: boolean = false;
  public checkCheckout: boolean = false;

  public checkVerificationMail: boolean = false;
  public verificationMessage: string="";

  public message = new BehaviorSubject<any>({});

  public emitAlertMessage(message: any, type: string) {
    let data = {
      message: message,
      type: type
    }
    this.message.next(data);
    // setTimeout(() => this.message.next(data), 0)
  }
}
