import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonBaseService } from 'src/app/service/base-service/common-base.service';
import { CommonDataSharingService } from 'src/app/service/base-service/common-data-sharing.service';

@Component({
  selector: 'app-header-mid',
  templateUrl: './header-mid.component.html',
  styleUrls: ['./header-mid.component.scss'],
})
export class HeaderMidComponent implements OnInit, OnDestroy {
  public addOpenClass: boolean = false;

  @Input() public headerLogo: any;

  public selectedSearch: string = '';
  public searchList: Array<string> = ['Product', 'Category'];

  public searchForm: FormGroup;
  private subscription: Subscription[] = [];

  private warehouseId: string = '';
  private searchSuggestApi: string = 'suggest';
  public suggestionList: Array<string> = [];

  public showSuggestion: boolean = false;
  private searchIndicator: boolean = true;

  public isSubmitted: boolean = false;
  public selectedSuggestion: any;

  constructor(
    private router: Router,
    private commonDataSharing: CommonDataSharingService,
    private CommonBaseService: CommonBaseService
  ) {}

  ngOnInit(): void {
    this.selectedSearch = this.searchList[0];
    this.createForm();
    this.subscription.push(
      this.commonDataSharing.clearSearchtext.subscribe((flag: boolean) => {
        if (flag) {
          this.searchForm.patchValue({
            query: '',
          });
        }
      })
    );
    this.subscription.push(
      this.commonDataSharing.warehouseId.subscribe((warehouse: any) => {
        if (warehouse) {
          this.warehouseId = JSON.stringify(warehouse);
          this.selectedSearch = this.searchList[0];
        }
      })
    );
    this.searchForm
      .get('query')
      .valueChanges.pipe(debounceTime(200))
      .subscribe((result: any) => {
        if (result !== '' && this.searchIndicator) {
          this.CommonBaseService.searchSuggest(
            `${this.searchSuggestApi}?query=${this.searchForm.value.query}&type=${this.selectedSearch}`,
            this.warehouseId
          ).subscribe((suggestion: any) => {
            this.suggestionList = suggestion.data;
            if (this.suggestionList.length !== 0) {
              this.showSuggestion = true;
            }
          });
        } else {
          this.searchIndicator = true;
          this.showSuggestion = false;
        }
      });
  }

  onFocus() {
    if (this.suggestionList.length !== 0) {
      this.showSuggestion = true;
    }
  }

  private createForm() {
    this.searchForm = new FormGroup({
      query: new FormControl('', [Validators.maxLength(200)]),
    });
  }

  public onSearch() {
    this.router.navigateByUrl(
      `/search?type=${this.selectedSearch}&keyword=${
        this.searchForm.get('query').value
      }`
    );
    this.showSuggestion = false;
  }

  public openNiceSelect() {
    this.addOpenClass = !this.addOpenClass;
  }

  public changeSelectSearch(value: string) {
    this.selectedSearch = value;
  }

  // public handleArrowKey(e) {
  //   console.log(e);
  // }

  public autoCloseForDropdown(e: any) {
    if (!e.target.closest('.search-box-inner-wrap')) {
      this.showSuggestion = false;
    }
  }

  public autoCloseSuggestion(e: any) {
    if (!e.target.closest('.headerList')) {
      this.addOpenClass = false;
    }
  }

  public onSuggestionClicked(suggestion: any) {
    this.searchIndicator = false;
    this.searchForm.patchValue({
      query: suggestion.title,
    });
    this.showSuggestion = false;
    this.onSearch();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
