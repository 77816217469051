<div class="footer-bottom">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
              <div class="copy-left-text">
                  <p>Copyright &copy; 
                      <a routerLink="/" class="text-capitalize">{{configData.title}}</a> 
                      {{currentYear}}. 
                      All Rights Reserved.</p>
              </div>
          </div>
          <div class="col-lg-6 col-md-6">
              <div class="copy-right-image">
                <p>Powered By <a href="https://ekbana.com/" target="_blank"> EKbana Solutions.</a></p>
              </div>
          </div>
      </div>
  </div>
</div>
