import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-mid',
  templateUrl: './footer-mid.component.html',
  styleUrls: ['./footer-mid.component.scss']
})
export class FooterMidComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
