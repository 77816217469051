import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss']
})
export class FooterBottomComponent implements OnInit {
  @Input() public configData: any;
  public currentYear:number;

  constructor() { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
