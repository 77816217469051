import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FooterMidComponent } from './footer-mid.component';

const routes: Routes = [{ path: '', component: FooterMidComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FooterMidRoutingModule { }
