import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { AuthService } from './service/authorization/auth.service';
import { CommonBaseService } from './service/base-service/common-base.service';
import { CommonDataSharingService } from './service/base-service/common-data-sharing.service';
import { CommonMetaService } from './service/base-service/common-meta.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'sewa-ordering-web-frontend';
  profileApi = 'profile/show';
  profileInfo: any;

  isBrowser: boolean;
  sticky: boolean = false;
  scrollheight: number = 163;

  configApi = 'config';

  configData: any;

  @HostListener('window:scroll') onScroll(e: Event): void {
    // var scroll = document.getElementById('header-sticky').scrollTop;
    if (this.commonDataSharing.getIsBrowser()) {
      var scroll =
        window.scrollY +
        this.scrollheight -
        document.getElementById('header-sticky').offsetTop;
      if (scroll < 300) {
        this.sticky = false;
      } else {
        this.sticky = true;
      }
      if (window.scrollY < 400) {
        var scrollUp = document.getElementById('scrollUp');
        scrollUp.classList.add('d-none');
      } else {
        var scrollUp = document.getElementById('scrollUp');
        scrollUp.classList.remove('d-none');
      }
    }
  }

  constructor(
    private commonBaseService: CommonBaseService,
    private commonDataSharing: CommonDataSharingService,
    public authService: AuthService,
    private metaService: CommonMetaService
  ) { }

  ngOnInit(): void {
    this.isBrowser = this.commonDataSharing.getIsBrowser();
    this.commonDataSharing.emitLogiNStatus(this.authService.isLoggednIn());
    this.commonDataSharing.loginStatus.subscribe((res: any) => {
      if (res) {
        this.getProfile();
      }
    })
    this.getConfig();
  }

  getConfig() {
    this.commonBaseService
      .getWithPublicKey(this.configApi)
      .subscribe((res: any) => {
        this.configData = res.data;
        this.commonDataSharing.emitConfigData(this.configData);
        this.setSiteColor(this.configData?.color);
        this.metaService.setTitle(this.configData.title);
        var metaTags = [
          {
            name: 'description',
            content: res.meta.socialTags.description,
          },
          { property: 'og:title', content: res.meta.socialTags['og:title'] },
          {
            proprety: 'og:description',
            content: res.meta.socialTags['og:description'],
          },
          {
            property: 'og:image',
            content: res.meta.socialTags['og:image'],
          },
          {
            name: 'twitter:description',
            content: res.meta.socialTags['twitter:description'],
          },
          {
            name: 'twitter:image',
            content: res.meta.socialTags['twitter:image'],
          },
          {
            name: 'twitter:title',
            content: res.meta.socialTags['twitter:title'],
          },
        ];
        this.metaService.updateMetaTag(metaTags);
        this.metaService.setAppFavicon(
          this.configData.id,
          this.configData.pageData.sitelogo
        );
      });
  }

  setSiteColor(color: any) {
    Object.keys(color).forEach(property => {
      // console.log(property, color[property])
      document.documentElement.style.setProperty(
        `--${property}`,
        color[property]
      );
    });
    // document.documentElement.style.setProperty('--primaryColor', this.configData.color.oldPriceColor);
  }

  onActivate() {
    // window.scroll(0, 0);
    if (this.commonDataSharing.getIsBrowser()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  getProfile() {
    this.commonBaseService
      .getWithAuth(this.profileApi, '')
      .subscribe((res: any) => {
        this.profileInfo = res.data;
        // this.uploadButton = false;
        // this.spinner.hide();
        this.commonDataSharing.emitProfile(this.profileInfo);
      });
  }
}
