import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log('Enabling prod');
  enableProdMode();
  if(window){
    window.console.log=function(){};
  } else {
    console.log=function(){};
  }
   console.log("Production enabled, this console.log won't appear");
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
