<div class="footer-top section-pb section-pt-60">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">

        <div class="widget-footer mt-40">
          <h6 class="title-widget" [innerHtml]="footerSection['section1 title']"></h6>

          <div class="contact-call-wrap">
            <img [src]="footerSection?.logo" alt="" width="75">
            <div class="footer-call">
              <!-- <p>Hotline Free 24/24:</p> -->

              <h6 [innerHtml]="footerSection['section1 phone']"></h6>
              <h6 [innerHtml]="footerSection['section1 mobile']"></h6>
            </div>
          </div>
          <div class="footer-addres">
            <p [innerHtml]="footerSection['section1 address']"></p>
            <p [innerHtml]="footerSection['section1 email']"></p>
          </div>

          <ul class="social-icons">
            <li *ngIf="footerSection['section5 facebook']">
              <a class="facebook social-icon" [href]="footerSection['section5 facebook']" title="Facebook"
                target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li *ngIf="footerSection['section5 twitter']">
              <a class="twitter social-icon" [href]="footerSection['section5 twitter']" title="Twitter" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li *ngIf="footerSection['section5 instagram']">
              <a class="instagram social-icon" [href]="footerSection['section5 instagram']" title="Instagram"
                target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li *ngIf="footerSection['section5 linkedin']">
              <a class="linkedin social-icon" [href]="footerSection['section5 linkedin']" title="Linkedin"
                target="_blank">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li *ngIf="footerSection['section5 rss']">
              <a class="rss social-icon" [href]="footerSection['section5 rss']" title="Rss" target="_blank">
                <i class="fas fa-rss"></i>
              </a>
            </li>
          </ul>

        </div>

      </div>
      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="widget-footer mt-40">
          <h6 class="title-widget" [innerHTML]="footerSection['section2 title']"></h6>
          <ul class="footer-list">
            <li>
              <a [routerLink]="['/page', footerSection['section2 link1']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content1']"></a>
            </li>
            <li><a [routerLink]="['/page', footerSection['section2 link2']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content2']"></a></li>
            <li><a [routerLink]="['/page', footerSection['section2 link3']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content3']"></a></li>
            <li><a [routerLink]="['/page', footerSection['section2 link4']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content4']"></a></li>
            <li><a [routerLink]="['/page', footerSection['section2 link5']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content5']"></a></li>
            <li><a [routerLink]="['/page', footerSection['section2 link6']?.split('/').pop()]"
                [innerHTML]="footerSection['section2 content6']"></a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="widget-footer mt-40">
          <h6 class="title-widget" [innerHTML]="footerSection['section3 title']"></h6>
          <ul class="footer-list">
            <li [innerHTML]="footerSection['section3 content1']"></li>
            <li [innerHTML]="footerSection['section3 content2']"></li>
            <li [innerHTML]="footerSection['section3 content3']"></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="widget-footer mt-40">
          <h6 class="title-widget" [innerHTML]="footerSection['section4 title']"></h6>
          <p [innerHTML]="footerSection['section4 description']"></p>
          <ul class="footer-list">
            <li><a [href]="footerSection['section4 googleplay link']"><img [src]="footerSection['section4 googleplay']"
                  alt=""></a></li>
            <li><a [href]="footerSection['section4 appstore link']"><img [src]="footerSection['section4 appstore']"
                  alt=""></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
