import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterTopRoutingModule } from './footer-top-routing.module';
import { FooterTopComponent } from './footer-top.component';


@NgModule({
  declarations: [FooterTopComponent],
  imports: [
    CommonModule,
    FooterTopRoutingModule
  ],
  exports: [
    FooterTopComponent
  ]
})
export class FooterTopModule { }
