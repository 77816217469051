<div class="header-top-area d-none d-lg-block bg-white border-bm-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="header-top-settings">
          <ul class="nav align-items-center">
            <li class="curreny-wrap ml-0" [attr.data-value]="warehouseInfo?.id">
              {{selectedWarehouse}}
              <i class="fa fa-angle-down ml-1"></i>
              <ul class="dropdown-list curreny-list">
                <li *ngFor="let warehouse of headerTop" [attr.data-value]="warehouse?.id">
                  <a (click)="onWarehouseClick(warehouse, template)">{{warehouse.title}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="top-info-wrap text-right">
          <ul class="my-account-container">
            <li *ngIf="authService.isLoggednIn()" class="text-capitalize"><a routerLink="/profile">My account</a></li>
            <li *ngIf="!authService.isLoggednIn()" class="text-capitalize"><a routerLink="/login">Login</a> / <a
                routerLink="/register">Register</a></li>
            <li><a routerLink="/offer" class="text-capitalize">Offer</a></li>
            <li *ngIf="authService.isLoggednIn()"><a class="text-capitalize" (click)="confirmLogout(logoutTemplate)">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- ------------------------- Modal Starts ---------------------------- -->
<!-- /*
* Warehouse Change Modal
*/ -->
<div bsModal #template="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="warehouse-modal-body"
  id="modal">
  <div class="modal-dialog modal-center">
    <div class="modal-content">
      <div class="modal-body" id="warehouse-modal-body">
        <button type="button" class="close pull-right" aria-label="Close" (click)="template.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center">
          <h4 class="my-3">You are about to change the city</h4>
          <h5 class="flush-txt-color">This will delete your products from cart.</h5>
          <h6 class="flush-txt-color">Are you sure?</h6>
        </div>
        <div class="my-4 text-center">
          <button type="button" class="btn" (click)="template.hide()">Cancel</button>
          <button type="button" class="btn ml-2" (click)="onChangeCity(template)">Yes</button>
          <!-- <button type="button" class="btn" (click)="template.hide()">Cancel</button>
          <button type="button" class="btn ml-2" (click)="onChangeCity(template)">Change City</button> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /*
* Logout Modal
*/ -->
<div bsModal #logoutTemplate="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="logout-modal"
  id="modal">
  <div class="modal-dialog modal-center">
    <div class="modal-content">
      <div class="modal-body" id="logout-modal">
        <button type="button" class="close pull-right" aria-label="Close" (click)="logoutTemplate.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="">
          <h4 class="mt-3">Are you sure you want to logout?</h4>
        </div>
        <div class="my-4 text-right">
          <button type="button" class="btn " (click)="logoutTemplate.hide()">No</button>
          <button type="button" class="btn ml-2" (click)="logout(logoutTemplate)">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
