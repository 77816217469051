import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderBottomRoutingModule } from './header-bottom-routing.module';
import { HeaderBottomComponent } from './header-bottom.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [HeaderBottomComponent],
  imports: [
    CommonModule,
    HeaderBottomRoutingModule,
    CollapseModule.forRoot(),
    ReactiveFormsModule
  ],
  exports: [
    HeaderBottomComponent
  ]
})
export class HeaderBottomModule { }
