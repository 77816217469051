<div class="haeader-mid-area border-bm-gray d-none d-lg-block">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-2 col-md-4 col-5">
        <div class="logo-area">
          <a routerLink="/"><img [src]="headerLogo?.pageData?.logo" /></a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="search-box-wrapper">
          <div class="search-box-inner-wrap">
            <form
              class="search-box-inner border-2"
              [formGroup]="searchForm"
              (ngSubmit)="onSearch()"
            >
              <div class="search-select-box">
                <div
                  class="nice-select headerList"
                  tabindex="0"
                  (window:mouseup)="autoCloseForDropdown($event)"
                  (click)="openNiceSelect()"
                  [ngClass]="{ open: addOpenClass, '': !addOpenClass }"
                >
                  <span class="current">{{ selectedSearch }}</span>
                  <ul class="list">
                    <li
                      *ngFor="let item of searchList; let i = index"
                      data-value="{{ item }}"
                      (click)="changeSelectSearch(item)"
                      class="option"
                      [ngClass]="{ selected: selectedSearch == item }"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="search-field-wrap">
                <input
                  type="text"
                  class="search-field"
                  placeholder="Search {{ selectedSearch }}..."
                  formControlName="query"
                  (window:mouseup)="autoCloseSuggestion($event)"
                />
                <!-- <input
                  type="text"
                  class="search-field"
                  placeholder="Search {{ selectedSearch }}..."
                  (focus)="onFocus()"
                  formControlName="query"
                  (window:mouseup)="autoCloseSuggestion($event)"
                /> -->

                <div class="search-btn">
                  <button type="submit" [disabled]="!searchForm.valid">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
            <div
              *ngIf="
                !isSubmitted &&
                searchForm.controls['query'].invalid &&
                (searchForm.controls['query'].dirty ||
                  searchForm.controls['query'].touched)
              "
              class="alert alert-danger validate-message text-center"
            >
              <div *ngIf="searchForm.controls['query'].errors.maxlength">
                <!-- Search text cannot be more than 200 characters. -->
                Search Field is too long.
              </div>
            </div>

            <section class="filter-wrapper" *ngIf="showSuggestion">
              <ul class="filter-select">
                <li
                  class="filter-select-list"
                  *ngFor="let suggestion of suggestionList"
                  (click)="onSuggestionClicked(suggestion)"
                >
                  <!-- <a class="suggestion py-1" >
                    {{suggestion}}
                  </a> -->
                  <img
                    class="search-product-img"
                    [src]="suggestion.img"
                    alt=""
                  />
                  <a class="suggestion py-1 search-list-title">
                    {{ suggestion.title }}
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="customer-wrap">
          <div class="single-costomer-box">
            <div class="single-costomer">
              <p>
                <i class="far fa-check-circle"></i>
                <span>Free Delivery</span>
              </p>
            </div>
          </div>

          <div class="single-costomer-box">
            <div class="single-costomer">
              <p>
                <i class="fas fa-lock"></i>
                <span>Safe Payment</span>
              </p>
            </div>
          </div>

          <div class="single-costomer-box">
            <div class="single-costomer">
              <p>
                <i class="far fa-bell"></i>
                <span>24/7 Support</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
