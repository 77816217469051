import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AlertMessageComponent } from './alert-message.component';

const routes: Routes = [{ path: '', component: AlertMessageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlertMessageRoutingModule { }
