<div class="haeader-bottom-area bg-white header-sticky sticky-top" id="header-sticky"
  [ngClass]="{'is-sticky': childMessage, '': !childMessage}">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-10 d-none d-lg-block">

        <div class="main-menu-area">
          <!--  Start Mainmenu Nav-->
          <nav class="main-navigation">
            <ul>
              <li class="active category-list">
                <a routerLink="/">Home</a>
              </li>

              <li *ngFor="let category of categories" class="category-list">
                <a [routerLink]="['/category', category?.slug]" (mouseover)="onMouseOver($event, category?.id)" (mouseleave)="mouseLeave()">{{category?.title}}
                  <i class="fa fa-angle-down" *ngIf="category?.subcategories?.length !== 0 "></i>
                </a>
                <ul (mouseover)="onMouseOver($event, category?.id)" (mouseleave)="mouseLeave()"
                  [ngClass]="{'mega-menu': category?.subcategories?.length >1, 'sub-menu': category?.subcategories?.length==1, 'row': category?.subcategories?.length >=2, 'show-subcategories': selectedCategory === 'child-'+ category?.id}"
                  [id]="'child-' + category?.id"
                  *ngIf="category.subcategories.length !== 0 ">
                  <li *ngFor="let subcategory of category?.subcategories; let last = last;" [ngClass]="{'col-lg-6': category?.subcategories?.length == 2, 'col-lg-4': category?.subcategories?.length > 2, 'pr-2': !last}">
                    <a [routerLink]="['/category', subcategory?.slug]">{{subcategory?.title}}</a>
                    <ul *ngIf="subcategory?.subcategories.length!==0 ">
                      <li *ngFor="let sub of subcategory?.subcategories" class="pt-0 mr-0 d-block">
                        <a [routerLink]="['/category', sub?.slug]">{{sub.title}}</a>
                      </li>
                    </ul>
                  </li>
                </ul>

              </li>
            </ul>
          </nav>

        </div>
      </div>

      <div class="col-5 col-md-6 d-block d-lg-none">
        <div class="logo">
          <a routerLink="/"><img [src]="headerBottomLogo?.pageData?.logo" height="40" alt="">
          </a>
        </div>
      </div>

      <div class="col-lg-2 col-md-6 col-7">
        <div class="right-blok-box d-flex">

          <div class="user-wrap" *ngIf="authService.isLoggednIn()">
            <a routerLink="/wishlist">
              <span class="cart-total" [innerHTML]="authService.isLoggednIn()? favouriteInfo?.data?.length: 0"></span>
              <i class="far fa-heart"></i>
            </a>
            <!-- <a routerLink="/offer" class="primary-txt text-uppercase">
              Offer
            </a> -->
          </div>


          <div class="shopping-cart-wrap">
            <a routerLink="/cart">
              <i class="icon fas fa-shopping-cart"></i>
              <span class="cart-total" [innerHTML]="cartInfo?.cartProducts?.length" *ngIf="cartInfo">
              </span>
              <span class="cart-total" *ngIf="!cartInfo">0
              </span>
              <!-- <span class="cart-total-amunt" *ngIf="!authService.isLoggednIn()">
                {{currencyValue}} 0
              </span> -->
              <span class="cart-total-amunt">
                {{currencyValue}} {{cartInfo?.orderAmount}}
              </span>
            </a>
            <ul class="mini-cart">
              <div class="cart-group">
                <li class="cart-item" *ngFor="let cart of cartInfo?.cartProducts">
                  <div class="cart-image">
                    <a [routerLink]="['/product', cart?.product?.slug]">
                      <img alt="" [src]="cart?.product?.icon" *ngIf="cart?.product?.images.length === 0">
                      <img alt="" [src]="cart?.product?.images[0].imageName" *ngIf="cart?.product?.images.length !== 0">
                    </a>
                  </div>
                  <div class="cart-title">
                    <a [routerLink]="['/product', cart?.product?.slug]">
                      <h4>{{cart?.product?.title}} <span *ngIf="checkMultiplePrice && cart?.selectedUnit?.size"
                          class="text-capitalize">({{cart?.selectedUnit?.size}})</span></h4>
                    </a>
                    <div class="quanti-price-wrap">
                      <span class="quantity">{{cart?.quantity}} ×</span>
                      <div class="price-box">
                        <span class="new-price" *ngIf="!cart?.selectedUnit?.hasOffer">{{currencyValue}}
                          {{cart?.selectedUnit?.sellingPrice}}</span>
                        <span class="new-price" *ngIf="cart?.selectedUnit?.hasOffer">{{currencyValue}}
                          {{cart?.selectedUnit?.newPrice}}</span>
                      </div>
                    </div>
                    <a class="remove_from_cart" (click)="onDeleteCart(cart)" *ngIf="!cart?.loadSpinner">
                      <i class="fas fa-times"></i>
                    </a>
                    <a class="remove_from_cart" *ngIf="cart?.loadSpinner">
                      <i class='fas fa-spinner fa-spin'></i>
                    </a>
                  </div>
                </li>
              </div>
              <li class="subtotal-box" *ngIf="cartInfo?.cartProducts?.length !==0">
                <div class="subtotal-title">
                  <h3>Total :</h3><span>{{currencyValue}} {{cartInfo?.orderAmount}}</span>
                </div>
              </li>
              <li class="mini-cart-btns" *ngIf="cartInfo?.cartProducts?.length !==0">
                <div class="cart-btns">
                  <a routerLink="/cart">View cart</a>
                  <a routerLink="/checkout">Checkout</a>
                </div>
              </li>
              <li class="no-product" *ngIf="cartInfo?.cartProducts?.length ===0">
                <h6 class='m-0'>No Products in the cart.</h6>
              </li>
              <!-- <li class="no-product" *ngIf="!authService.isLoggednIn()">
                <h3>No Products in the cart.</h3>
              </li> -->
            </ul>
            <!-- <ul class="mini-cart" *ngIf="loadingIndicator">
              <div class="no-data pt-120 opacity-5 text-muted text-uppercase demo-chart text-center"
                [innerHTML]="loadingSpinner"></div>
            </ul> -->
          </div>

          <div class="mobile-menu-btn d-block d-lg-none">
            <div class="off-canvas-btn">
              <a (click)="onCanvasOpen()">
                <img src="assets/images/icon/bg-menu-block.png">
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<aside class="off-canvas-wrapper" [ngClass]="{'open': addClassOpen, '': !addClassOpen}">
  <div class="off-canvas-overlay" (click)="onCanvasClose()"></div>
  <div class="off-canvas-inner-content">
    <div class="btn-close-off-canvas" (click)="onCanvasClose()">
      <i class="fas fa-times"></i>
    </div>

    <div class="off-canvas-inner">

      <div class="search-box-offcanvas">
        <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
          <input type="text" placeholder="Search product..." formControlName="query">
          <button class="search-btn" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </form>
      </div>

      <!-- mobile menu start -->
      <div class="mobile-navigation">

        <!-- mobile menu navigation start -->
        <nav>
          <ul class="mobile-menu">
            <li class="menu-item-has-children">
              <a routerLink="/" aria-controls="home">Home</a>
            </li>
            <li class="menu-item-has-children" *ngFor="let category of categories">

              <span class="menu-expand" (click)="category.isCollapsed = !category.isCollapsed"
                [attr.aria-expanded]="!category.isCollapsed" aria-controls="shop"
                [innerHTML]="!category.isCollapsed? minusIcon: plusIcon"
                *ngIf="category.subcategories.length !== 0 "></span>

              <a (click)="category.isCollapsed = !category.isCollapsed;  onCanvasClose()"
                [attr.aria-expanded]="!category.isCollapsed" aria-controls="shop"
                [routerLink]="['/category', category?.slug]">
                {{category.title}}
              </a>

              <ul class="megamenu dropdown" [id]="'sub' + category?.id" *ngIf="category.subcategories.length !== 0 "
                [collapse]="category.isCollapsed" [isAnimated]="true">

                <li class="mega-title has-children" *ngFor="let subcategory of category?.subcategories">

                  <span class="menu-expand" (click)="subcategory.isCollapsed = !subcategory.isCollapsed "
                    [attr.aria-expanded]="!subcategory.isCollapsed " aria-controls="shopLayout"
                    [innerHTML]="!subcategory.isCollapsed? minusIcon: plusIcon"
                    *ngIf="subcategory.subcategories.length !== 0 "></span>

                  <a (click)="subcategory.isCollapsed  = !subcategory.isCollapsed ; onCanvasClose()"
                    [attr.aria-expanded]="!subcategory.isCollapsed " aria-controls="shopLayout"
                    [routerLink]="['/category', subcategory?.slug]">
                    {{subcategory?.title}}
                  </a>
                  <ul class="dropdown" [id]="'sub-category' + subcategory.id" [collapse]="subcategory.isCollapsed"
                    [isAnimated]="true" *ngIf="subcategory.subcategories.length !== 0 ">

                    <li *ngFor="let sub of subcategory.subcategories;">

                      <a [routerLink]="['/category', sub?.slug]"
                        (click)="onCanvasClose()">
                        {{sub.title}}
                      </a>
                    </li>
                  </ul>

                </li>
              </ul>
            </li>
          </ul>
        </nav>
        <!-- mobile menu navigation end -->
      </div>
      <!-- mobile menu end -->


      <div class="header-top-settings offcanvas-curreny-lang-support">
        <ul class="nav align-items-center">
          <li class="curreny-wrap" (click)="openNiceSelect()" [ngClass]="{'open': addOpenClass, '': !addOpenClass}">
            {{selectedWarehouse}}
            <i class="fa fa-angle-down"></i>
            <ul class="dropdown-list curreny-list">
              <li *ngFor="let warehouse of headerBottomLogo?.warehouse">
                <a (click)="onWarehouseClick(warehouse)">{{warehouse?.title}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- offcanvas widget area start -->
      <div class="offcanvas-widget-area">
        <div class="top-info-wrap text-left text-black">
          <h5>My Account</h5>
          <ul class="offcanvas-account-container">
            <li *ngIf="authService.isLoggednIn()"><a routerLink="/profile">My account</a></li>
            <li *ngIf="!authService.isLoggednIn()"><a routerLink="/login">Login </a>/ <a
                routerLink="/register">Register</a></li>
            <li><a routerLink="/cart">Cart</a></li>
            <li><a routerLink="/wishlist">Wishlist</a></li>
            <li><a routerLink="/checkout">Checkout</a></li>
          </ul>
        </div>

      </div>
      <!-- offcanvas widget area end -->
    </div>
  </div>
</aside>
