import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseComponent } from './component/common/base/base.component';
import { HeaderTopModule } from './component/common/header/header-top/header-top.module';
import { HeaderMidModule } from './component/common/header/header-mid/header-mid.module';
import { HeaderBottomModule } from './component/common/header/header-bottom/header-bottom.module';
import { FooterBottomModule } from './component/common/footer/footer-bottom/footer-bottom.module';
import { FooterMidModule } from './component/common/footer/footer-mid/footer-mid.module';
import { FooterTopModule } from './component/common/footer/footer-top/footer-top.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './service/authorization/token.interceptor';
import { AlertMessageModule } from './component/common/alert-message/alert-message.module';
import { ToastrModule } from 'ngx-toastr';
import { BaseModule } from './component/common/base/base.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    BaseModule,
    HeaderTopModule,
    HeaderMidModule,
    HeaderBottomModule,
    FooterBottomModule,
    FooterMidModule,
    FooterTopModule,
    HttpClientModule,
    AlertMessageModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      progressBar: true,
      progressAnimation: "decreasing"
    })
  ],
  providers:[
    Title,
    Meta,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
