import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonDataSharingService {

  public currency = new BehaviorSubject<string>("");
  public configData = new BehaviorSubject<any>({});

  public CategoryList = new BehaviorSubject<any[]>([]);

  loginStatus: Observable<boolean>;
  public _loginStatus = new BehaviorSubject<boolean>(false);

  public cartUpdate = new BehaviorSubject<boolean>(false);
  public wishlistCountUpdate = new BehaviorSubject<boolean>(false);

  public wishList = new BehaviorSubject<any>([]);
  public cartList = new BehaviorSubject<any>({});
  public tagList = new BehaviorSubject<any>([]);

  public profile = new BehaviorSubject<any>([]);
  public warehouseId = new BehaviorSubject<string>('');

  public checkProfileUpdate = new BehaviorSubject<boolean>(false);
  public checkCode = new BehaviorSubject<boolean>(false);

  public verificationInfo = new BehaviorSubject<any>({});
  public tagBreadCrumb = new BehaviorSubject<any>({});

  public clearSearchtext = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.loginStatus = this._loginStatus.asObservable();
  }

  public emitCurrency(currency: string) {
    this.currency.next(currency);
  }

  public emitSearchBoolean(flag: boolean) {
    this.clearSearchtext.next(flag);
  }

  public emitVerificationInfo(info: any) {
    this.verificationInfo.next(info);
  }

  // -- Emit checkStock key for condition to display availability of stock --
  public emitConfigData(value: boolean) {
    this.configData.next(value);
  }

  public emitCategoryList(val: any) {
    this.CategoryList.next(val);
  }

  public emitTagBreadCrumb(val: any) {
    this.tagBreadCrumb.next(val);
  }

  public emitProfile(val) {
    this.profile.next(val);
  }

  public emitLogiNStatus(status: boolean) {
    this._loginStatus.next(status);
  }

  public emitcartUpdate(status: boolean) {
    this.cartUpdate.next(status);
  }

  public emitwishlistCountUpdate(count: boolean) {
    this.wishlistCountUpdate.next(count);
  }

  public emitProfileUpdate(status: boolean) {
    this.checkProfileUpdate.next(status);
  }

  public emitCheckCode(status: boolean) {
    this.checkCode.next(status);
  }

  public emitWishList(status: any) {
    this.wishList.next(status);
  }

  public emitCartList(status: any) {
    this.cartList.next(status);
  }

  public emitTagList(tag: Array<any>) {
    this.tagList.next(tag);
  }

  public emitWarehouseId(status: any) {
    this.warehouseId.next(status);
  }

  public getIsBrowser(){
    let isBrowser = isPlatformBrowser(this.platformId);
    return isBrowser;
  }

  public getLocalStorage(key: string){
    if(this.getIsBrowser()){
     return localStorage.getItem(key);
    }
  }

  public setLocalStorage(key: string, value: any){
    if(this.getIsBrowser()){
      localStorage.setItem(key, value);
    }
  }
  public removeLocalStorage(key: string){
    if(this.getIsBrowser()){
      localStorage.removeItem(key);
    }
  }
  public removeSessionStorage(key: string){
    if(this.getIsBrowser()){
      sessionStorage.removeItem(key);
    }
  }

  public setSessionStorage(key: string, value: string){
    if(this.getIsBrowser()){
      sessionStorage.setItem(key, value);
    }
  }

  public getSessionStorage(key: string){
    if(this.getIsBrowser()){
     return sessionStorage.getItem(key);
    }
  }

  public convertDecimal(number: number){
    return parseFloat(number?.toFixed(2))
  }
}
