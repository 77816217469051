<div class="newletter-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="newletter-wrap d-flex justify-content-center">
          <div class="row align-items-center">

            <div class="newsletter-footer mb-30">
              <!-- <input type="text" placeholder="Your email address...">
              <div class="subscribe-button">
                <button class="subscribe-btn">Subscribe</button>
              </div> -->

              <a class="newsletter-button" routerLink="/be-our-partner"> Be Our Partner</a>

            </div>
            <!-- </div> -->
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
