import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertMessageRoutingModule } from './alert-message-routing.module';
import { AlertMessageComponent } from './alert-message.component';
import { AlertModule } from 'ngx-bootstrap/alert';


@NgModule({
  declarations: [AlertMessageComponent],
  imports: [
    CommonModule,
    AlertMessageRoutingModule,
    AlertModule.forRoot()
  ],
  exports: [
    AlertMessageComponent
  ]
})
export class AlertMessageModule { }
