import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['./footer-top.component.scss']
})
export class FooterTopComponent implements OnInit {
  @Input() public footerSection: any;

  constructor() { }

  ngOnInit(): void {
  }


}
