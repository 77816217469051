import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BaseComponent } from './component/common/base/base.component';
import { AuthGuard } from './service/authorization/auth.guard';
import { LoginGuard } from './service/authorization/login.guard';

const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: '',
    component: BaseComponent,
    data: {
      breadcrumb: 'Home',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./component/homepage/homepage.module').then(
            (m) => m.HomepageModule
          ),
      },
      {
        path: 'login',
        canActivate: [LoginGuard],
        loadChildren: () =>
          import('./component/login-register/login/login.module').then(
            (m) => m.LoginModule
          ),
        data: {
          breadcrumb: 'Login',
        },
      },
      {
        path: 'register',
        canActivate: [LoginGuard],
        loadChildren: () =>
          import('./component/login-register/register/register.module').then(
            (m) => m.RegisterModule
          ),
        data: {
          breadcrumb: 'Register',
        },
      },
      {
        path: 'category/:slug',
        data: {
          breadcrumb: 'Category',
        },
        loadChildren: () =>
          import('./component/category-page/category-page.module').then(
            (m) => m.CategoryPageModule
          ),
      },
      // {
      //   path: 'category/:pageslug',
      //   data: {
      //     breadcrumb: 'Category',
      //   },
      //   loadChildren: () =>
      //     import('./component/category-page/category-page.module').then(
      //       (m) => m.CategoryPageModule
      //     ),
      // },
      {
        path: 'tag/:id',
        data: {
          breadcrumb: 'Category',
        },
        loadChildren: () =>
          import('./component/category-page/category-page.module').then(
            (m) => m.CategoryPageModule
          ),
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Profile',
        },
        loadChildren: () =>
          import('./component/my-account/my-account.module').then(
            (m) => m.MyAccountModule
          ),
      },

      {
        path: 'cart',
        data: {
          breadcrumb: 'Cart',
        },
        loadChildren: () =>
          import('./component/cart/cart.module').then((m) => m.CartModule),
      },

      {
        path: 'checkout',
        data: {
          breadcrumb: 'Checkout',
        },
        loadChildren: () =>
          import('./component/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          ),
      },
      {
        path: 'wishlist',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Wishlist',
        },
        loadChildren: () =>
          import('./component/wishlist/wishlist.module').then(
            (m) => m.WishlistModule
          ),
      },
      {
        path: 'product/:id',
        data: {
          breadcrumb: '',
        },
        loadChildren: () =>
          import('./component/product-detail/product-detail.module').then(
            (m) => m.ProductDetailModule
          ),
      },
      {
        path: 'offer',
        data: {
          breadcrumb: 'Offer',
        },
        loadChildren: () =>
          import('./component/offer/offer.module').then((m) => m.OfferModule),
      },
      {
        path: 'offer/:id',
        data: {
          breadcrumb: 'Offer',
        },
        loadChildren: () =>
          import('./component/offer/offer.module').then((m) => m.OfferModule),
      },
      {
        path: 'verify',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Verify',
        },
        loadChildren: () =>
          import('./component/verify/verify.module').then(
            (m) => m.VerifyModule
          ),
      },
      {
        path: 'forgot-password',
        data: {
          breadcrumb: 'Forgot Password',
        },
        loadChildren: () =>
          import(
            './component/login-register/forgot-password/forgot-password.module'
          ).then((m) => m.ForgotPasswordModule),
      },
      {
        path: 'reset-password',
        data: {
          breadcrumb: 'Reset Password',
        },
        loadChildren: () =>
          import('./component/reset-password/reset-password.module').then(
            (m) => m.ResetPasswordModule
          ),
      },
      {
        path: 'search',
        data: {
          breadcrumb: 'Search',
        },
        loadChildren: () =>
          import('./component/search/search.module').then(
            (m) => m.SearchModule
          ),
      },
      {
        path: 'page/:slug',
        data: {
          breadcrumb: 'Slug',
        },
        loadChildren: () =>
          import('./component/page/page.module').then((m) => m.PageModule),
      },
      {
        path: 'be-our-partner',
        loadChildren: () =>
          import('./component/page/be-our-partner/be-our-partner.module').then(
            (m) => m.BeOurPartnerModule
          ),
      },
    ],
  },
  {
    path: 'page-not-found',
    data: {
      breadcrumb: 'Search',
    },
    loadChildren: () =>
      import('./component/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      ),
  },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
