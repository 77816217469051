import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/authorization/auth.service';
import { CommonDataSharingService } from 'src/app/service/base-service/common-data-sharing.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  @Input() public headerTop = [];
  public loggedIn: boolean = false;
  private subscription: Subscription[]= [];
  public selectedWarehouse: string = 'Select City';
  private cartProduct: Array<any> = [];
  public warehouseInfo: any;

  constructor(
    private commonDataSharing: CommonDataSharingService,
    public authService: AuthService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.subscription.push(this.commonDataSharing.loginStatus.subscribe(
      (res: any) => {
        // this.loggedIn = res;
        this.loggedIn = this.authService.isLoggednIn();
      }
    ));
    if (this.commonDataSharing.getLocalStorage('wId')) {
      let wid = parseInt(this.commonDataSharing.getLocalStorage('wId'));
      this.headerTop?.map((warehouse) => {
        if (warehouse?.id === wid) {
          this.selectedWarehouse = warehouse?.title;
          this.warehouseInfo = warehouse;
          return;
        }
      });
      this.commonDataSharing.emitWarehouseId(wid);
    } else {
      let wid = this.headerTop[0]?.id;
      this.commonDataSharing.setLocalStorage('wId', wid);
      this.commonDataSharing.emitWarehouseId(wid);
      this.selectedWarehouse = this.headerTop[0]?.title;
      this.warehouseInfo = this.headerTop[0];
    }
    this.subscription.push(this.commonDataSharing.cartList.subscribe((cart: any) => {
      if (cart) {
        this.cartProduct = cart?.cartProducts;
      }
    }));
  }

  public onWarehouseClick(warehouse: any, template: ModalDirective) {
    this.warehouseInfo = warehouse;
    if (this.selectedWarehouse !== warehouse?.title) {
      if (this.cartProduct?.length !== 0) {
        template.show();
      } else {
        this.onChangeCity(template);
      }
    }
  }

  public onChangeCity(template: ModalDirective) {
    this.commonDataSharing.removeSessionStorage('page');
    this.commonDataSharing.removeSessionStorage('banner');
    this.selectedWarehouse = this.warehouseInfo?.title;
    this.commonDataSharing.emitWarehouseId(this.warehouseInfo?.id);
    this.commonDataSharing.setLocalStorage('wId', this.warehouseInfo?.id);
    if(this.commonDataSharing.getLocalStorage('code')){
      this.commonDataSharing.removeLocalStorage('code');
    }
    this.router.navigateByUrl('/');
    template.hide();
  }

  public confirmLogout(template: ModalDirective) {
    template.show();
  }

  public logout(template: ModalDirective) {
    this.authService.logout();
    this.commonDataSharing.emitcartUpdate(true);
    template.hide();
  }

  ngOnDestroy(){
    this.subscription.forEach((subscription: Subscription)=>{
      subscription.unsubscribe();
    });
  }
}
