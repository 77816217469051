import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommonMetaService {

  constructor(
    private meta: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) { }

  setAppFavicon(id: string, basepath: string) {
    this._document.getElementById('appFavicon').setAttribute('href', basepath);
  }

  updateMetaTag(metaTags: MetaDefinition[]) {
    metaTags.forEach((m) => this.meta.updateTag(m));
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
