import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterMidRoutingModule } from './footer-mid-routing.module';
import { FooterMidComponent } from './footer-mid.component';


@NgModule({
  declarations: [FooterMidComponent],
  imports: [
    CommonModule,
    FooterMidRoutingModule
  ],
  exports:[
    FooterMidComponent
  ]
})
export class FooterMidModule { }
