import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderMidRoutingModule } from './header-mid-routing.module';
import { HeaderMidComponent } from './header-mid.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [HeaderMidComponent],
  imports: [
    CommonModule,
    HeaderMidRoutingModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderMidComponent
  ]
})
export class HeaderMidModule { }
