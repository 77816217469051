import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterBottomRoutingModule } from './footer-bottom-routing.module';
import { FooterBottomComponent } from './footer-bottom.component';


@NgModule({
  declarations: [FooterBottomComponent],
  imports: [
    CommonModule,
    FooterBottomRoutingModule
  ],
  exports: [
    FooterBottomComponent
  ]
})
export class FooterBottomModule { }
