import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/authorization/auth.service';
import { CommonBaseService } from 'src/app/service/base-service/common-base.service';
import { CommonDataSharingService } from 'src/app/service/base-service/common-data-sharing.service';

@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss'],
})
export class HeaderBottomComponent implements OnInit, OnDestroy {
  private warehouseId: string = "0";
  @Input() public headerBottomLogo: any;

  public addClassOpen: boolean = false;
  @Output() onOpenCanvas: EventEmitter<any> = new EventEmitter<any>();
  @Input() public childMessage: string;

  private categoryApi: string = 'category';
  public categories:Array<any> = [];

  private cartApi: string = 'cart';
  public cartInfo: any;

  private favouriteApi: string = 'favourite/products';
  public favouriteInfo: any;

  public addOpenClass: boolean = false;
  public currencyValue: any;
  private isBrowser: boolean = false;

  public plusIcon: string = '<i class="fas fa-plus"></i>';
  public minusIcon : string= '<i class="fas fa-minus"></i>';

  public selectedWarehouse: string = 'Select City';
  public checkMultiplePrice: boolean = false;
  private subscription: Subscription[]= [];

  public selectedCategory: string ="";
  public searchForm: FormGroup;

  constructor(
    private renderer: Renderer2,
    private commonBaseService: CommonBaseService,
    private commonDataSharing: CommonDataSharingService,
    public authService: AuthService,
    private router: Router
  ) {
    this.createForm();
    this.isBrowser = this.commonDataSharing.getIsBrowser();
    this.subscription.push(this.commonDataSharing.configData.subscribe((config: any) => {
      if (config?.multiplePrice) {
        let wid = parseInt(this.commonDataSharing.getLocalStorage('wId'));
        config?.warehouse?.map((warehouse) => {
          if (warehouse?.id === wid) {
            this.selectedWarehouse = warehouse?.title;
            return;
          }
        });

      }
      this.checkMultiplePrice = config?.multiplePrice;
        this.currencyValue = config?.currency;
    }));
    this.subscription.push(this.commonDataSharing.cartUpdate.subscribe((value: any) => {
      if (value) {
        this.getCart();
        this.getFavourite();
        if (this.authService.isLoggednIn()) {
          this.commonDataSharing.emitcartUpdate(false);
        }
      }
    }));

    this.subscription.push(this.commonDataSharing.warehouseId.subscribe((warehouseId: any) => {
      this.warehouseId = JSON.stringify(warehouseId);
      if (warehouseId) {
        this.getCategory();
        // if (this.authService.isLoggednIn()) {
          this.getCart();
          this.getFavourite();
        // }
        this.commonDataSharing.checkCode.subscribe((coupon: any) => {
          if (coupon) {
            this.getCart();
            this.commonDataSharing.emitCheckCode(false);
          }
        });
      }
    }));
  }

  ngOnInit(): void {}

  private createForm(){
    this.searchForm = new FormGroup({
      query: new FormControl('', [Validators.maxLength(200)])
    })
  }

  // -- Open Sidebar Menu for mobile view --
  public onCanvasOpen() {
    this.addClassOpen = true;
    this.onOpenCanvas.emit(this.addClassOpen);
    if (this.isBrowser) {
      this.renderer.addClass(document.body, 'fix');
      // this.document.querySelector('body').classList.add('fix');
    }
  }

  // -- Close Sidebar Menu for mobile view --
  public onCanvasClose() {
    this.addClassOpen = false;
    if (this.isBrowser) {
      this.renderer.removeClass(document.body, 'fix');
      // this.document.querySelector('body').classList.remove('fix');
    }
  }

  // -- Toggle dropdown menu --
  public openNiceSelect() {
    this.addOpenClass = !this.addOpenClass;
  }

  private getCategory() {
    this.commonBaseService
      .getWithAuth(this.categoryApi, this.warehouseId)
      .subscribe((res: any) => {
        this.categories = [...res?.data];
        this.categories?.map((category) => {
          category.isCollapsed = true;
          category?.subcategories.map((subcategory) => {
            subcategory.isCollapsed = true;
          });
        });
        this.commonDataSharing.emitCategoryList(this.categories);
      }, error=>{
        this.commonDataSharing.emitCategoryList(this.categories);
      });
  }

  private getCart() {
    // if (this.authService.isLoggednIn()) {
      this.commonBaseService
        .getCart(this.cartApi, this.warehouseId)
        .subscribe((res: any) => {
          this.cartInfo = res.data;
          this.cartInfo.deliveryCharge = parseFloat(
            this.cartInfo.deliveryCharge.toFixed(2)
          );
          this.cartInfo.discount = parseFloat(
            this.cartInfo.discount.toFixed(2)
          );
          this.cartInfo.orderAmount = parseFloat(
            this.cartInfo.orderAmount.toFixed(2)
          );
          this.cartInfo.subTotal = parseFloat(
            this.cartInfo.subTotal.toFixed(2)
          );
          this.cartInfo.total = parseFloat(this.cartInfo.total.toFixed(2));
          this.commonDataSharing.emitCartList(this.cartInfo);
          if(this.cartInfo && this.cartInfo.cartProducts.length!==0){
            this.cartInfo?.cartProducts.map((cart: any) =>{
              cart.loadSpinner = false;
            })
          }
          if(!this.authService.isLoggednIn() && !this.commonDataSharing.getLocalStorage('cartNum')){
            this.commonDataSharing.setLocalStorage('cartNum', this.cartInfo.cartNumber);
          }
        });
    // } else {
    //   this.commonDataSharing.emitCartList([]);
    // }
  }

  public onDeleteCart(cart: any) {
    // this.loadingIndicator = true;
    cart.loadSpinner = true;
    this.commonBaseService
      .delete('cart-product/' + cart?.id)
      .subscribe((res: any) => {
        // this.cartInfo = {};
        // this.loadingIndicator = false;
        cart.loadSpinner = false;
        this.commonDataSharing.emitcartUpdate(true);
        // this.getCart();
      },
      error => {
        cart.loadSpinner = false;
      });
  }

  private getFavourite() {
    if (this.authService.isLoggednIn()) {
      this.commonBaseService
        .getWithAuth(this.favouriteApi, this.warehouseId)
        .subscribe((res: any) => {
          this.favouriteInfo = res;
          this.commonDataSharing.emitWishList(this.favouriteInfo);
        });
    } else {
      this.commonDataSharing.emitWishList([]);
    }
  }

  public onWarehouseClick(warehouse: any) {
    this.selectedWarehouse = warehouse?.title;
    this.commonDataSharing.emitWarehouseId(warehouse.id);
    this.onCanvasClose();
  }

  public onMouseOver(e: any, id: any){
    this.selectedCategory = 'child-' + id;
  }

  public mouseLeave(){
    this.selectedCategory = "";
  }

  public onSearch(){
    this.router.navigateByUrl(`/search?type=Product&keyword=${this.searchForm.get('query').value}`);
    this.onCanvasClose();
  }

  ngOnDestroy(){
    this.subscription.forEach((subscription: Subscription)=>{
      subscription.unsubscribe();
    })
  }
}
