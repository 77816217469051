import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonMessageService } from 'src/app/service/base-service/common-message.service';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit, OnDestroy {
  public dismissible: boolean = true;
  public globalMsg: string = '';
  public type: string = '';
  public isOpen: boolean = false;

  private subscription: Subscription[] = [];

  constructor(private commonMsgService: CommonMessageService) {
  }

  ngOnInit(): void {
    this.isOpen = false;
    this.subscription.push(this.commonMsgService.message.subscribe((data: any) => {
      if (data?.message && data?.type) {
        this.isOpen = true;
        this.globalMsg = data.message;
        this.type = data.type;
      }
    }
    ));
  }

  onClosed(): void {
    // this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
    this.commonMsgService.message.next({});
  }

  ngOnDestroy() {
    this.commonMsgService.message.next({});
    this.subscription.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    })
  }
}
