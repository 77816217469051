import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommonMessageService } from './common-message.service';
import { AuthService } from '../authorization/auth.service';
import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonDataSharingService } from './common-data-sharing.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CommonBaseService {
  public cartSpinner: boolean = false;
  public wishlistSpinner: boolean = false;
  public loadingSpinner: boolean = false;
  private spinnerName: string = 'spinner';

  constructor(
    private http: HttpClient,
    private commonDatSharing: CommonDataSharingService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  uploadImage(file: File, reqUrl: any) {
    return from(
      new Promise((resolve, reject) => {
        let authorization = '';
        if (this.authService.isLoggednIn()) {
          authorization = `${this.authService.getToken().token_type} ${
            this.authService.getToken().access_token
          }`;
        }
        let formData: FormData = new FormData();
        let xhr = new XMLHttpRequest();
        formData.append('image', file, file.name);
        formData.append(
          'type',
          file.type.substring(file.type.lastIndexOf('/') + 1)
        );

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr.response);
            } else {
              reject(xhr.response);
            }
          }
        };

        xhr.open('POST', environment.baseUrl + reqUrl, true);
        xhr.setRequestHeader('Api-Key', environment.apiKey);
        xhr.setRequestHeader('Authorization', authorization);
        xhr.send(formData);
      })
    );
  }

  getWithPublicKey(reqUrl: any): Observable<Object> {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Api-Key': environment.apiKey,
    });
    let url: string = environment.baseUrl + reqUrl;
    return this.http.get(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  searchSuggest(reqUrl: any, warehouseId: string): Observable<Object> {
    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Api-Key': environment.apiKey,
      'Warehouse-Id': warehouseId,
    });
    let url: string = environment.baseUrl + reqUrl;
    return this.http.get(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  getWithAuth(
    reqUrl: any,
    warehouseId: string,
    pages?: number,
    cartNumber?: string,
    auth?: any
  ): Observable<Object> {
    let authorization = '';
    let header = new HttpHeaders({
      'Warehouse-Id': warehouseId,
      'Api-Key': environment.apiKey,
    });
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
      header = header.set('Authorization', authorization);
    } else {
      if (auth) {
        header = header.set('Authorization', `Bearer ${auth}`);
      }
    }
    if (pages) {
      header = header.set('perPage', String(pages));
    }
    if (cartNumber) {
      header = header.set('Cart-Number', cartNumber);
    }

    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.get(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }
  getCart(
    reqUrl: any,
    warehouseId: string,
    pages?: number
  ): Observable<Object> {
    let authorization = '';
    let header = new HttpHeaders({
      'Warehouse-Id': warehouseId,
      'Api-Key': environment.apiKey,
    });
    let coupon = this.commonDatSharing.getLocalStorage('code');
    if (coupon) {
      header = header.set('Coupon', coupon);
    }
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
      header = header.set('Authorization', authorization);
    } else {
      let cartNum = this.commonDatSharing.getLocalStorage('cartNum');
      if (cartNum) {
        header = header.append('Cart-Number', cartNum);
      }
    }
    if (pages) {
      header = header.set('perPage', String(pages));
    }

    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.get(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          (error.error.errors[0].message === 'Coupon you requested is not Valid')
        ) {
          this.commonDatSharing.removeLocalStorage('code');
          this.commonDatSharing.emitCheckCode(true);
        }
        this.cartSpinner = false;
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  update(model: Object, reqUrl: any, warehouseId: string): Observable<Object> {
    let authorization = '';
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
    }
    let header = new HttpHeaders({
      'Warehouse-Id': warehouseId,
      'Api-Key': environment.apiKey,
      Authorization: authorization,
    });

    if (reqUrl.substr(0, reqUrl.indexOf('/')) == 'cart-product') {
      if (!this.authService.isLoggednIn()) {
        let cartNum = this.commonDatSharing.getLocalStorage('cartNum');
        header = header.append('Cart-Number', cartNum);
      }
    }
    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.patch(url, model, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.cartSpinner = false;
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  saveWithHeader(
    model: Object,
    reqUrl: any,
    warehouseId: string
  ): Observable<Object> {
    let authorization = '';
    let cartNum = '';
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
    }

    let header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Warehouse-Id': warehouseId,
      'Api-Key': environment.apiKey,
      Authorization: authorization,
    });

    if (reqUrl == 'cart-product' || reqUrl == 'cart/bulk') {
      if (!this.authService.isLoggednIn()) {
        cartNum = this.commonDatSharing.getLocalStorage('cartNum');
        header = header.append('Cart-Number', cartNum);
      }
    }
    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);

    return this.http.post(url, model, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.cartSpinner = false;
        this.wishlistSpinner = false;
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  /**
   * @param model Form value
   * @param reqUrl Api
   * @param auth optional parameter for guest login used in add delivery address
   */
  save(model: Object, reqUrl: any, auth?: any): Observable<Object> {
    let url: string = environment.baseUrl + reqUrl;
    let header = new HttpHeaders({});
    // let getUrl = this.restApiService.modifyRestUrl(url);
    if (auth) {
      header = header.append('Authorization', `Bearer ${auth}`);
      header = header.append('Api-Key', environment.apiKey);
    }
    return this.http.post(url, model, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadingSpinner = false;
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  saveGuestLogin(
    model: Object,
    reqUrl: any,
    cartNumber?: any
  ): Observable<Object> {
    let url: string = environment.baseUrl + reqUrl;
    let header = new HttpHeaders({});
    // let getUrl = this.restApiService.modifyRestUrl(url);
    if (cartNumber) {
      header = header.append('Cart-Number', cartNumber);
    }
    return this.http.post(url, model, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadingSpinner = false;
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  delete(reqUrl: any): Observable<Object> {
    let authorization = '';
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
    }
    let header = new HttpHeaders({
      'Api-Key': environment.apiKey,
      Authorization: authorization,
    });
    if (reqUrl.substr(0, reqUrl.indexOf('/')) == 'cart-product') {
      if (!this.authService.isLoggednIn()) {
        let cart = this.commonDatSharing.getLocalStorage('cartNum');
        if (cart) {
          header = header.append('Cart-Number', cart);
        }
      }
    }
    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.delete(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.wishlistSpinner = false;
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  checkoutCart(reqUrl: any, model: any, auth?: any): Observable<Object> {
    let authorization = '';
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
    } else {
      if (auth) {
        authorization = `Bearer ${auth}`;
      }
    }

    let header = new HttpHeaders({
      'Api-Key': environment.apiKey,
      web: '1',
      Authorization: authorization,
      DeliveryId: JSON.stringify(model.deliveryId),
      PaymentMethodId: JSON.stringify(model.paymentMethodId),
      Note: (model.note),
    });
    if (this.commonDatSharing.getLocalStorage('code')) {
      header = header.set(
        'Code',
        this.commonDatSharing.getLocalStorage('code')
      );
    }
    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.delete(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.loadingSpinner = false;
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  // applyCoupon(reqUrl: string, cartId: string, cartNum?: any): Observable<Object> {
  //   let authorization = '';
  //   let header = new HttpHeaders({
  //     'Cart-Id': cartId,
  //     'Api-Key': environment.apiKey,
  //   });
  //   if (this.authService.isLoggednIn()) {
  //     authorization = `${this.authService.getToken().token_type} ${
  //       this.authService.getToken().access_token
  //     }`;
  //     header = header.set('Authorization', authorization);
  //   }else{
  //     if(cartNum){
  //       header = header.append('Cart-Number', cartNum)
  //     }
  //   }
  //   let url: string = environment.baseUrl + reqUrl;
  //   // let getUrl = this.restApiService.modifyRestUrl(url);
  //   return this.http.get(url, { headers: header }).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       this.emitErrorMessage(error);
  //       this.spinner.hide(this.spinnerName);
  //       return throwError(error);
  //     })
  //   );
  // }

  applyCoupon(
    reqUrl: string,
    warehouseId: string,
    code: any,
    cartNum?: any
  ): Observable<Object> {
    let authorization = '';
    let header = new HttpHeaders({
      'Warehouse-Id': warehouseId,
      'Api-Key': environment.apiKey,
      Coupon: code,
    });
    if (this.authService.isLoggednIn()) {
      authorization = `${this.authService.getToken().token_type} ${
        this.authService.getToken().access_token
      }`;
      header = header.set('Authorization', authorization);
    } else {
      if (cartNum) {
        header = header.append('Cart-Number', cartNum);
      }
    }
    let url: string = environment.baseUrl + reqUrl;
    // let getUrl = this.restApiService.modifyRestUrl(url);
    return this.http.get(url, { headers: header }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.emitErrorMessage(error);
        this.spinner.hide(this.spinnerName);
        return throwError(error);
      })
    );
  }

  emitErrorMessage(error: HttpErrorResponse) {
    error.error?.errors.forEach((errr: any) => {
      if(errr.message && errr.message !== "Please log in"){
        this.toastr.error(errr.message);
      }
    });
  }
}
