<div class="main-wrapper" *ngIf="configData">
  <header class="header">
    <app-header-top [headerTop]="configData?.warehouse" *ngIf="configData"></app-header-top>
    <app-header-mid [headerLogo]="configData"></app-header-mid>
    <app-header-bottom id="header-sticky" [childMessage]="sticky" [headerBottomLogo]="configData">
    </app-header-bottom>
  </header>
  <div class="bg-light" *ngIf="configData?.verifyUser && authService?.isLoggednIn() && !profileInfo?.verified">
    <div class="py-4  container">
      <p class="lead text-danger">
        <a class="lead text-danger" style="font-weight: 500;" routerLink="/profile">
          Please verify your account first before checkout!
        </a>
      </p>
    </div>
  </div>
  <!-- <app-alert-message></app-alert-message> -->
  <router-outlet></router-outlet>
  <footer>
    <app-footer-top [footerSection]="configData?.pageData" *ngIf="configData"></app-footer-top>
    <app-footer-mid *ngIf="configData"></app-footer-mid>
    <app-footer-bottom *ngIf="configData" [configData]="configData"></app-footer-bottom>
  </footer>
</div>

<a id="scrollUp" style="position: fixed;" class="d-none" (click)="onActivate()"><i class="fa fa-angle-up"></i></a>
